import React from 'react';
import PropTypes from 'prop-types';
import Header from 'ic/ui-elem/Header';
import Deck from 'ic/ui-elem/nursery/DeckNG.jsx';
import { ForDesktop, ForNonDesktop } from 'ic/ui-elem/Responsive';
import cloneDeep from 'fast-clone';
import { setupInitialState, mapItems, setupMutations, superConnect, mapGetters, mapActions } from 'core/util/module-utils';
import logger from 'score/logSvc';
import { getThemeFactory } from '@ic-theme';
import oneBuyStatus from 'ec/util/oneBuyStatus';
import { getters as prodSvcGetters, actions as prodSvcActions } from 'seco/productsSvc/productsSvcMod';
import { getBasicProduct } from 'seco/productsSvc/product-factories';
import ProductStateCard from './ProductStateCardMod';
import * as cls from './ProductUpsell.styles';
import { NAME as appReducerName } from '@spa-core/store/app/constants';

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'ProductUpsellCarouselMod';
const th = getThemeFactory(name, {
  minWidth: 200,
  mobileMinWidth: 150,
  useFavourites: true,
  noGutter: false,
  disableOuterBorders: true,
  disableInnerBorders: false,
  enableOuterPadding: false,
});

// Mudules data, this is the initial data for an instance
const initialState = {};

const log = logger.getLogger(name);
const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  // Adding an extra getter that is not only a variable but some calculation based on the state
  mapGetters(prodSvcGetters(state, ownProps), instance, 'productSvc', ['getProduct', 'getBasicProduct', 'lastUpdate']);
  instance.oneBuyUserGroupMap = state?.reducers?.[appReducerName]?.sessionConfig?.oneBuyUserGroupMap;
  instance.oneBuyOrderPlacedMap = state?.reducers?.[appReducerName]?.sessionConfig?.oneBuyOrderPlacedMap;

  return instance;
};

// ################# ACTIONS  #################
export const actions = (dispatch, ownProps) => ({
  act: {
    ...mapActions(prodSvcActions(dispatch, ownProps), 'productSvc', ['fetchProductsInBatch']),
  },
});

// ################# MUTATIONS  #################

const mutations = {};

// ################# MODULE SETUP DON T TOUCH  #################
export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  getters,
  actions,
  mutations: setupMutations(mutations, conf) // eslint-disable-line
};

const ProductsList = (p) => {
  if (p.promotionalImageSrc) {
    const promotionItem = {
      images: {
        primary: {
          large: {
            altText: 'Dax Alcogel 85 - handsprit - 600 ml',
            code: null,
            format: 'product',
            galleryIndex: 0,
            imageType: 'GALLERY',
            url: p.promotionalImageSrc,
            width: null,
          },
          medium: {
            altText: 'Dax Alcogel 85 - handsprit - 600 ml',
            code: null,
            format: 'product',
            galleryIndex: 0,
            imageType: 'GALLERY',
            url: p.promotionalImageSrc,
            width: null,
          },
          small: {
            altText: 'Dax Alcogel 85 - handsprit - 600 ml',
            code: null,
            format: 'product',
            galleryIndex: 0,
            imageType: 'GALLERY',
            url: p.promotionalImageSrc,
            width: null,
          },
          xlarge: {
            altText: 'Dax Alcogel 85 - handsprit - 600 ml',
            code: null,
            format: 'product',
            galleryIndex: 0,
            imageType: 'GALLERY',
            url: p.promotionalImageSrc,
            width: null,
          },
        },
      },
      url: p.promotionalImageLink,
      promotionalText: p.promotionalText,
    };
    p.products.unshift(promotionItem);
  }
  return (
    <Deck
      className={'e2e-ver-upsell-carousel'}
      min={p.minWidth}
      align={'center'}
      oneRow={p.showCarousel}
      noGutter={p.noGutter}
      autoPlay={p.autoPlay}
      autoPlaySpeed={p.autoPlaySpeed}
    >
      {p.products.map((product) => {
        const cards = ['NONE'];
        if (product.oneBuyOnlyProduct === true || product.oneBuyOnlyProduct === 'true') {
          const oneBuyState = oneBuyStatus(
            p.oneBuyOrderPlacedMap,
            p.oneBuyUserGroupMap,
            product.oneBuyOnlyProduct,
            product.oneBuyUserGroupId
          );
          if (oneBuyState !== 'NONE') {
            cards.push(oneBuyState);
          }
        }
        return cards.map((obState, ind) => (
          <ProductStateCard
            iid={product.code}
            key={product.code}
            basicProduct={product}
            obState={obState}
            ind={ind}
            isCarousel={p.isCarousel}
          />
        ));
      })}
    </Deck>
  );
};

class ProductUpsellCarouselMod extends React.Component {
  /**
   * propTypes
   */
  static get propTypes() {
    return {
      content: PropTypes.string,
      headerText: PropTypes.string,
      title: PropTypes.string,
      customTitleContent: PropTypes.string,
      showCarousel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      autoPlay: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      autoPlaySpeed: PropTypes.any,

      promotionalText: PropTypes.string,
      promotionalImageSrc: PropTypes.string,
      promotionalImageLink: PropTypes.string,
    };
  }

  componentDidMount() {
    const p = this.props;
    if (p.content) {
      const pCodesContent = JSON.parse(p.content);
      // Check the parsed content, if it is list of product codes then the element should be of string type
      if (pCodesContent.length > 0 && typeof pCodesContent[0] === 'string') {
        p.act.productSvc.fetchProductsInBatch(pCodesContent, true);
      }
    }
  }

  render() {
    const p = this.props;
    const theme = th();
    if (!p.content) {
      return null;
    }
    let products;
    const content = JSON.parse(p.content);
    if (content.length && content.length > 0) {
      if (typeof content[0] === 'string') {
        products = content.map((code) => p.productSvc.getBasicProduct(code)).filter((prod) => prod.name);
      } else {
        products = content.map(getBasicProduct);
      }
    } else {
      return null;
    }
    let autoPlay;
    if (p.autoPlay) {
      if (p.autoPlay === 'true') {
        autoPlay = true;
      } else {
        autoPlay = false;
      }
    } else {
      autoPlay = false;
    }

    let showCarousel;
    if (p.showCarousel) {
      if (p.showCarousel === 'true') {
        showCarousel = true;
      } else {
        showCarousel = false;
      }
    } else {
      showCarousel = false;
    }

    return (
      <div
        data-ssr-key={p.SSRKey}
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
        className="smartEditComponent"
      >
        <div className={cls.upsell + ' e2e-ver-upsell'}>
          <div
            className={
              theme.enableOuterPadding
                ? 'px-4 ic-margin-tb ic-padding-t w-full border-box ic-mod-product-grid'
                : 'ic-margin-tb ic-padding-t w-full border-box ic-mod-product-grid'
            }
          >
            {p.customTitleContent ? <div dangerouslySetInnerHTML={{ __html: p.customTitleContent }} /> : ''}
            <Header intro={p.headerText} header={p.title} seoLevel={theme.seoLevel ? theme.seoLevel : '1'} />
            <ForDesktop>
              <ProductsList
                products={products}
                add={p.add}
                categoryProductListingPage={false}
                responsive={false}
                minWidth={theme.minWidth}
                useFavourites={theme.useFavourites}
                entries={p.entries}
                showCarousel={showCarousel}
                autoPlay={autoPlay}
                autoPlaySpeed={p.autoPlaySpeed}
                noGutter={theme.noGutter}
                oneBuyOrderPlacedMap={p.oneBuyOrderPlacedMap}
                oneBuyUserGroupMap={p.oneBuyUserGroupMap}
                promotionalImageSrc={p.promotionalImageSrc}
                promotionalImageLink={p.promotionalImageLink}
                promotionalText={p.promotionalText}
                isCarousel={true}
              />
            </ForDesktop>
            <ForNonDesktop>
              <ProductsList
                products={products}
                add={p.add}
                categoryProductListingPage={false}
                responsive={false}
                minWidth={theme.mobileMinWidth}
                useFavourites={theme.useFavourites}
                entries={p.entries}
                showCarousel={showCarousel}
                autoPlay={autoPlay}
                autoPlaySpeed={p.autoPlaySpeed}
                noGutter={theme.noGutter}
                oneBuyOrderPlacedMap={p.oneBuyOrderPlacedMap}
                oneBuyUserGroupMap={p.oneBuyUserGroupMap}
                promotionalImageSrc={p.promotionalImageSrc}
                promotionalImageLink={p.promotionalImageLink}
                promotionalText={p.promotionalText}
                isCarousel={true}
              />
            </ForNonDesktop>
          </div>
        </div>
      </div>
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, ProductUpsellCarouselMod);

import React from 'react';
import PropTypes from 'prop-types';
import { getThemeFactory } from '@ic-theme';
import * as cls from './Header.styles';
import classNames from 'classnames';

const theme = getThemeFactory('Header', {});

// ################# RENDER  #################
class Header extends React.Component {
  static get propTypes() {
    return {
      intro: PropTypes.string,
      header: PropTypes.string,
      seoLevel: PropTypes.oneOf(['1', '2', '3', '4', '5']),
    };
  }
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const level = 'h' + this.props.seoLevel;
    const header = this.props.header ? this.props.header : '';

    return (
      <div className={(cls['header-section'], 'header-section')}>
        {this.props.intro ? <div className={classNames('p-2', cls.intro, 'header-intro')}>{this.props.intro}</div> : ''}
        {this.props.header && level === 'h1' ? <div className={classNames(cls.header, 'header')}>{header}</div> : ''}
        {this.props.header && level === 'h2' ? <div className={'ic-half-padding-b ' + cls.header2}>{header}</div> : ''}
      </div>
    );
  }
}

Header.defaultProps = {
  seoLevel: '1',
};

export default Header;

/**
 * Business logic for one buy offer button. Pass in oneBuyOrderPlacedMap and oneBuyUserGroupMap from
 * sessionSvc. Also pass in onleBuyOnlyProduct and oneBuyUserGroupId from the product. There is a md
 * file next to the javascript file describing the different cases. (For the curious). Back from the
 * function you get 'SHOW', 'NONE' or 'DISABLED'..
 * @param {*} oneBuyOrderPlacedMap
 * @param {*} oneBuyUserGroupMap
 * @param {*} oneBuyOnlyProduct
 * @param {*} oneBuyUserGroupId
 */
const oneBuyStatus = (
  oneBuyOrderPlacedMap,
  oneBuyUserGroupMap,
  oneBuyOnlyProduct,
  oneBuyUserGroupId,
  updatingSessionConfig = undefined
) => {
  if (updatingSessionConfig === true) {
    return 'DISABLED';
  }
  if (!oneBuyUserGroupMap || !oneBuyOrderPlacedMap) return 'NONE';
  const oneBuyPlacedOrder = oneBuyOrderPlacedMap[oneBuyUserGroupId];
  if (oneBuyPlacedOrder) return 'NONE';
  const oneBuyUserGroup = oneBuyUserGroupMap[oneBuyUserGroupId];
  if (!oneBuyPlacedOrder && !oneBuyUserGroup && oneBuyOnlyProduct) return 'SHOW';
  if (!oneBuyPlacedOrder && oneBuyUserGroup && oneBuyOnlyProduct) return 'DISABLED';
  return 'NONE';
};

export default oneBuyStatus;
